export default {
    LOG_LEVELS: [
        {
            level: undefined,
            text: "ANY"
        },
        {
            level: 0,
            text: 'INFO'
        },
        {
            level: 1,
            text: 'WARNING'
        },
        {
            level: 2,
            text: 'CRUCIAL'
        },
        {
            level: 3,
            text: 'LETHAL'
        }
    ]
}