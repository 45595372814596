<template>
  <div>
    <div class="popup-wrapper" :style="!popupOpen ? { display: 'none' } : {}">
      <div class="popup-container">
        <div class="buttons">
          <button
            class="prev-button"
            @click="showPrevLog"
            :disabled="logIndex === 0 && pageNo === 1"
          >
            {{ "Prev" }}
          </button>
          <button class="next-button" @click="showNextLog">
            {{ "Next" }}
          </button>
        </div>
        <div class="popup-header">
          <div class="popup-title">LOG DETAILS</div>
          <a
            class="popup-button button-close"
            id="popup-button button-close"
            @click="$emit('popup-close')"
          >
            <i class="fas fa-times"></i>
          </a>
        </div>
        <div class="popup-outer-container">
          <div class="popup-content">
            <div class="form-container">
              <h4>{{ "User ID" }}</h4>
              <p>{{ state.user_id }}</p>
            </div>
            <div class="form-container">
              <h4>{{ "Log Type" }}</h4>
              <p>{{ state.log_type }}</p>
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Module ID" }}</h4>
              <p>{{ state.module_id }}</p>
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "App ID" }}</h4>
              <p>{{ state.app_id }}</p>
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Device ID" }}</h4>
              <p>{{ state.device_id }}</p>
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Game ID" }}</h4>
              <p>{{ state.game_id }}</p>
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Version" }}</h4>
              <p>{{ state.version }}</p>
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Log Time" }}</h4>
              <p>{{ moment(state.log_time).format("DD/MM/YYYY HH:mm:ss") }}</p>
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Event Time" }}</h4>
              <p>
                {{ moment(state.event_time).format("DD/MM/YYYY HH:mm:ss.SSS") }}
              </p>
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Is Server" }}</h4>
              <p>{{ state.is_server }}</p>
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Is Editor" }}</h4>
              <p>{{ state.is_editor }}</p>
            </div>
          </div>
          <div class="popup-content">
            <div class="form-container-inline form-container">
              <h4>{{ "Exception Message" }}</h4>
              <textarea
                style="width=80% min-height: 150px"
                v-model="state.exception_message"
              />
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Stack Trace" }}</h4>
              <textarea
                style="width=80% min-height: 150px"
                v-model="state.exception_stack_trace"
              />
            </div>
            <div class="form-container-inline form-container">
              <h4>{{ "Backend Response" }}</h4>
              <textarea
                style="width=80% min-height: 150px"
                v-model="state.backend_response"
              />
            </div>
          </div>
        </div>
        <div class="detail-container">
          <div v-if="state.is_server && state.user_id">
            <Button
              :text="gameSessionDetails ? 'Close' : 'Get Details'"
              type="info"
              :loading="isDetailsLoading"
              :onClick="() => getGameSessionDetails()"
            />
          </div>
          <div>
            <div class="jsondata" v-if="gameSessionDetails">
              <json-viewer sort boxed copyable :value="gameSessionDetails" />
            </div>
          </div>
        </div>
        <div class="popup-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import moment from "moment";
import gameliftService from "../services/server";
import Button from "./common/Button.vue";
import "vue-json-viewer/style.css";

export default {
  name: "LogPopup",
  components: {
    Button,
  },
  props: ["content", "rows", "popupOpen"],
  emits: ["popup-close"],
  setup(props) {
    const state = reactive({
      app_id: "",
      user_id: "",
      backend_response: "",
      exception_stack_trace: "",
      exception_message: "",
      module_id: "",
      log_level: 0,
      version: "",
      log_time: 0,
      event_time: 0,
      is_editor: false,
      is_server: false,
      log_type: 0,
      device_id: "",
      game_id: "",
    });

    const isDetailsLoading = ref(false);
    const gameSessionDetails = ref(undefined);

    const logIndex = ref(0);
    const pageNo = ref(1);

    watch(
      () => props.content,
      (value) => {
        state.app_id = value.app_id;
        state.user_id = value.user_id;
        state.backend_response = value.backend_response;
        state.exception_stack_trace = value.exception_stack_trace;
        state.exception_message = value.exception_message;
        state.module_id = value.module_id;
        state.log_level = value.log_level;
        state.version = value.version;
        state.log_time = value.log_time;
        state.event_time = value.event_time;
        state.is_editor = value.is_editor;
        state.is_server = value.is_server;
        state.log_type = value.log_type;
        state.device_id = value.device_id;
        state.game_id = value.game_id;
        logIndex.value = value.newLogIndex;
        gameSessionDetails.value = undefined;
        pageNo.value = parseInt(
          document.getElementById("pagination-cursor").textContent
        );
      }
    );
    const showNextLog = async () => {
      if (props.rows[logIndex.value + 1]) {
        logIndex.value++;
        state.app_id = props.rows[logIndex.value].app_id;
        state.user_id = props.rows[logIndex.value].user_id;
        state.backend_response = props.rows[logIndex.value].backend_response;
        state.exception_stack_trace =
          props.rows[logIndex.value].exception_stack_trace;
        state.exception_message = props.rows[logIndex.value].exception_message;
        state.module_id = props.rows[logIndex.value].module_id;
        state.log_level = props.rows[logIndex.value].log_level;
        state.version = props.rows[logIndex.value].version;
        state.log_time = props.rows[logIndex.value].log_time;
        state.event_time = props.rows[logIndex.value].event_time;
        state.is_editor = props.rows[logIndex.value].is_editor;
        state.is_server = props.rows[logIndex.value].is_server;
        state.log_type = props.rows[logIndex.value].log_type;
        state.device_id = props.rows[logIndex.value].device_id;
        state.game_id = props.rows[logIndex.value].game_id;
        gameSessionDetails.value = undefined;
      } else {
        logIndex.value = await getNextPage();
        document.getElementById("popup-button button-close").click();
        const allRows = await getAllRows();
        Array.from(allRows)[1].click();
      }
    };

    const showPrevLog = async () => {
      if (props.rows[logIndex.value - 1]) {
        logIndex.value--;
        state.app_id = props.rows[logIndex.value].app_id;
        state.user_id = props.rows[logIndex.value].user_id;
        state.backend_response = props.rows[logIndex.value].backend_response;
        state.exception_stack_trace =
          props.rows[logIndex.value].exception_stack_trace;
        state.exception_message = props.rows[logIndex.value].exception_message;
        state.module_id = props.rows[logIndex.value].module_id;
        state.log_level = props.rows[logIndex.value].log_level;
        state.version = props.rows[logIndex.value].version;
        state.log_time = props.rows[logIndex.value].log_time;
        state.event_time = props.rows[logIndex.value].event_time;
        state.is_editor = props.rows[logIndex.value].is_editor;
        state.is_server = props.rows[logIndex.value].is_server;
        state.log_type = props.rows[logIndex.value].log_type;
        state.device_id = props.rows[logIndex.value].device_id;
        state.game_id = props.rows[logIndex.value].game_id;
        gameSessionDetails.value = undefined;
      } else {
        console.log(pageNo.value);
        if (pageNo.value !== 1) {
          logIndex.value = await getPrevPage();
          document.getElementById("popup-button button-close").click();
          const allRows = await getAllRows();
          Array.from(allRows)[Array.from(allRows).length - 1].click();
        }
      }
    };

    const getNextPage = async () => {
      pageNo.value++;
      return new Promise((resolve) => {
        document.getElementById("pagination-navigation-button-next").click();
        setTimeout(() => {
          resolve(0);
        }, 1000);
      });
    };

    const getPrevPage = async () => {
      pageNo.value--;
      return new Promise((resolve) => {
        document.getElementById("pagination-navigation-button-prev").click();
        setTimeout(() => {
          resolve(0);
        }, 1000);
      });
    };

    const getAllRows = () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(document.getElementsByClassName("row"));
        }, 1000);
      });
    };

    const getGameSessionDetails = () => {
      if (!state.is_server) return;

      if (gameSessionDetails.value) {
        gameSessionDetails.value = undefined;
        return;
      }

      isDetailsLoading.value = true;

      gameliftService
        .getGameSessionDetails(state.user_id)
        .then((result) => {
          if (result.success) {
            result.data.MatchmakerData = JSON.parse(result.data.MatchmakerData);
            gameSessionDetails.value = result.data;
          }
        })
        .finally(() => {
          isDetailsLoading.value = false;
        });
    };

    return {
      state,
      moment,
      showNextLog,
      logIndex,
      showPrevLog,
      pageNo,
      getNextPage,
      getPrevPage,
      getAllRows,
      getGameSessionDetails,
      gameSessionDetails,
      isDetailsLoading,
    };
  },
};
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container-old {
  width: 70%;
  height: 60%;
  border-radius: 25px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.popup-outer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.detail-container {
  max-height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-container {
  width: 70%;
  height: 70%;
  border-radius: 15px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.popup-content {
  width: 100%;
  height: 90%;
  align-self: flex-start;
}
.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.popup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.popup-button {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5px 15px;
}

.button-close {
  color: red;
}

.button-save {
  color: green;
}

.popup-title {
  font-size: 1.3rem;
  font-weight: bold;
}

.reward-table {
  width: 100%;
}
.row {
  width: 100%;
  display: grid;
}

.chest-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.jsondata {
  margin-top: 15px;
  text-align: left;
  overflow: scroll;
  max-height: 90%;
}

.prev-button {
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid gray;
  cursor: pointer;
  margin-left: 45%;
  max-width: 10%;
  display: block;
  transition: 0.3s;
}
.prev-button:hover {
  background-color: #bc6363;
  color: white;
}

.next-button {
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid gray;
  cursor: pointer;
  margin-right: 45%;
  max-width: 10%;
  display: block;
  transition: 0.3s;
}
.next-button:hover {
  background-color: #65b768;
  color: white;
}

.buttons {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
}

.horizontal-line {
  height: 1px;
  width: 100%;
  background: grey;
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>