import getAxios from './axios'

export default {
    createFleet: (data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/server/create-fleet', data).then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    deleteFleet: (data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/server/delete-fleet', data).then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    getGameSessionDetails: (gameSessionId) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/server/getGameSessionDetails?game_session_id=' + gameSessionId).then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    getAliases: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/server/aliases').then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    getBuilds: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/server/builds').then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    getFleets: () => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.get( '/api/server/fleets').then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
    updateAlias: (data) => {
        return new Promise((resolve, reject) => {
            getAxios().then(axios => {
                axios.post( '/api/server/update-alias', data).then(res => resolve(res.data)).catch(() => reject('ERROR'))
            })
        })
    },
}