<template>
  <div class="filter-container">
    <div class="filter">
      <div class="filtergrid">
        <div>
          <p>{{ "Sort By" }}</p>
          <select v-model="sortField">
            <option
              v-for="option in sortOptions"
              :value="option.field"
              v-bind:key="option.field"
            >
              {{ option.text || "No Sort Field" }}
            </option>
          </select>
        </div>
        <div v-for="filter in filters" v-bind:key="filter.field">
          <div v-if="filter.type == 'date'">
            <p>{{ filter.text }}</p>
            <DatePicker
              v-model="filter.value"
              mode="dateTime"
              :model-config="{
                type: 'number',
                mask: 'DD/MM/YYYY HH:mm',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  id="creation_date"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </DatePicker>
          </div>
          <div v-else-if="filter.field == 'log_level'">
            <p>{{ filter.text }}</p>
            <select v-model="filter.value">
              <option
                v-for="option in logConstants.LOG_LEVELS"
                :value="option.level"
                v-bind:key="option.level"
              >
                {{ option.text || "No Field Selected" }}
              </option>
            </select>
          </div>
          <div v-else>
            <p>{{ filter.text }}</p>
            <input v-model="filter.value" />
          </div>
          <input type="checkbox" @click="hideColumn(filter.field)">
          <label>Show/Hide</label><br>
        </div>
      </div>
    </div>
    <div class="filter-sort">
      <input type="checkbox" id="checkbox" v-model="isDesc" />
      <label for="checkbox">{{ "Descending Order" }}</label>
    </div>
    <div class="filter-sort">
      <input type="checkbox" id="checkbox2" v-model="useLegacy" />
      <label for="checkbox2">{{ "Use Legacy Log Table" }}</label>
    </div>
  </div>
  <div class="pagination">
    <button
      class="pagination-navigation-button"
      id="pagination-navigation-button-prev" 
      @click="prevPage"
      :disabled="pageIndex === 0"
    >
      {{ "Prev" }}
    </button>
    <div id="pagination-cursor" class="pagination-cursor">
      {{ pageIndex + 1 }}
    </div>
    <button
      class="pagination-navigation-button"
      id="pagination-navigation-button-next"
      @click="nextPage"
      :disabled="rows.length < pageSize"
    >
      {{ "Next" }}
    </button>
    <Button
      :loading="loading"
      text="Search"
      :onClick="() => applySearch()"
      type="info"
    />
  </div>
  <div class="row row-header">
    <div
      v-for="column in columns.filter((x) => x.show)"
      v-bind:key="column.field"
      class="cell cell-header"
      :style="{
        width: 100 / columns.filter((x) => x.show).length + '%',
        maxWidth: 100 / columns.filter((x) => x.show).length + '%',
      }"
    >
      {{ $t(column.text) }}
    </div>
  </div>
  <div id="rows"
    v-for="(row, index) in rows"
    class="row"
    v-bind:key="index"
    @click="() => openPopup(rows[index], rows, index)"
  >
    <div
      v-for="column in columns.filter((x) => x.show)"
      v-bind:key="column.key + row"
      :class="{ cellred: row['log_level'] == 3 }"
      class="cell"
      :style="{
        width: 100 / columns.filter((x) => x.show).length + '%',
        maxWidth: 100 / columns.filter((x) => x.show).length + '%',
      }"
    >
      {{
        column.type === "date"
          ? moment(row[column.field]).format("DD/MM/YYYY HH:mm:ss")
          : row[column.field]
      }}
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, onUpdated, onBeforeUnmount } from "vue";
import axios from "axios";
import moment from "moment";
import { notify } from "@kyvg/vue3-notification";
import { useI18n } from "vue-i18n";
import "v-calendar/dist/style.css";
import logConstants from "@/constants/logConstants";
import { useStore } from "vuex";
import Button from "./common/Button.vue";

export default {
  name: "Table",
  components: {
    Button,
  },
  props: {
    columns: { required: true },
    serverEndpoint: { required: true },
    pageSize: { required: true },
    initialSortField: { required: true },
    onLogClick: { required: false, type: Function },
  },
  emits: ["open-popup"],
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const pageIndex = ref(0);
    const rows = ref([]);
    const filters = reactive([]);
    const sortOptions = reactive([]);
    const sortField = ref(props.initialSortField);
    const isDesc = ref(true);
    const useLegacy = ref(false)

    const loading = ref(false);

    onMounted(() => {
      prepareFilters();
      prepareSortOptions();
      getRows();
    });
    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    const prepareSortOptions = () => {
      props.columns
        .filter((x) => x.filterable)
        .map((y) => {
          return {
            text: y.text,
            field: y.field,
          };
        })
        .forEach((element) => sortOptions.push(element));
    };

    const prepareFilters = () => {
      props.columns
        .filter((y) => y.filterable)
        .map((x) => {
          return {
            ...x
          };
        })
        .forEach((element) => {
          if (element.type === "date") {
            var elementBegin = JSON.parse(JSON.stringify(element));
            var elementEnd = JSON.parse(JSON.stringify(element));
            elementBegin.field += "_begin";
            elementBegin.text += " Begin";
            elementEnd.field += "_end";
            elementEnd.text += " End";
            elementEnd.value = element.default_end
            elementBegin.value = element.default_begin
            filters.push(elementBegin);
            filters.push(elementEnd);
          } else filters.push(element);
        });
    };

    const getRows = async (page = 0) => {
      loading.value = true;
      const newRows = await axios
        .post(props.serverEndpoint, {
          use_legacy_table: useLegacy.value,
          order_by: sortField.value,
          is_descending: isDesc.value,
          filters: filters.filter(
            (x) => x.value !== undefined && x.value !== ""
          ),
          page: page,
        })
        .then((res) => {
          loading.value = false;
          console.log(res);
          return res.data;
        });
      if (newRows.length > 0) {
        rows.value = newRows;
        pageIndex.value = page;
      } else if (newRows.length === 0) {
        rows.value = [];
        pageIndex.value = 0;
        notify({
          title: t("ERROR"),
          text: t("NOTIF.ERROR.NO_RESULTS_FOUND"),
          type: "error",
        });
      }
    };

    const openPopup = (content, rows, index) => {
      content.newLogIndex = index;
      props.onLogClick(content, rows, index);
    };

    const nextPage = () => {
      getRows(pageIndex.value + 1);
    };

    const prevPage = () => {
      getRows(pageIndex.value - 1);
    };

    const applySearch = () => {
      getRows(0);
    };

    const hideColumn = (fieldName) => {
      let col = props.columns.filter(col => col.field == fieldName)[0];
      col.show = !col.show;
    }

    return {
      pageIndex,
      rows,
      filters,
      nextPage,
      prevPage,
      applySearch,
      sortField,
      isDesc,
      moment,
      openPopup,
      loading,
      sortOptions,
      logConstants,
      hideColumn,
      useLegacy
    };
  },
};
</script>

<style scoped>
.title {
  align-self: start;
  margin-left: 40px;
}

.filtergrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  justify-content: space-between;
}

.table {
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 5%;
}
.row {
  flex-grow: 1;
  max-width: 100%;
  display: inline-flex;
  max-height: 70px;
  overflow: hidden;
  min-height: 30px;
}

.filter-container {
  border: 1px solid grey;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px;
  padding: 40px;
}

.filter {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.filter-sort {
  margin-top: 30px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row:hover {
  background-color: aliceblue;
  cursor: pointer;
}

.cell.cell-header {
  font-size: 1.15rem;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.cell {
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 0;
}

.cellred {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 0;
  background-color: rgba(216, 26, 0, 0.2);
}

.pagination {
  display: flex;
  align-items: center;
  padding: 15px;
}

.pagination-navigation-button {
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid gray;
  cursor: pointer;
  margin-right: 5px;
}

.pagination-navigation-button:disabled,
.pagination-navigation-button[disabled] {
  cursor: default;
}

.pagination-cursor {
  padding: 5px 10px;
  font-weight: 600;
}

.row.row-search {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>