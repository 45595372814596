<template>
    <div class="table">
        <custom-table server-endpoint="api/game-logs/get" :onLogClick="onLogClick" :page-size="50" :columns="columns" initial-sort-field="event_time" />
        <log-popup  :popup-open="logPopupOpen" :content="{ ...popupContent }" :rows="{ ...logRows }"  @popup-close="logPopupOpen = false" />
    </div>
</template>

<script>
import Table from "@/components/TableResponsive";
import LogPopup from "@/components/LogPopup";
import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "index",
  components: {
    "custom-table": Table,
    "log-popup": LogPopup
  },
  setup() {
        const route = useRoute();
        const logPopupOpen = ref(false);
        const popupContent = ref({})
        const logRows = ref({})
        const logIndex = ref(0);
        const openLogPopup = (content, rows, index) => {
            popupContent.value = content;
            logRows.value = rows;
            logIndex.value = index;
            logPopupOpen.value = true;
        }

        const columns = ref ([
        {
            text: "Log Level",
            field: "log_level",
            type: "number",
            filterable: true,
            show: true
        },
        {
            text: "Log Type",
            field: "log_type",
            type: "number",
            filterable: true,
            show: false
        },
        {
            text: "Module ID",
            field: "module_id",
            filterable: true,
            show: true
        },
        {
            text: "User ID",
            field: "user_id",
            filterable: true,
            value: route.query.user_id,
            show: true
        },
        {
            text: "Device ID",
            field: "device_id",
            filterable: true,
            value: route.query.device_id,
            show: true
        },
        {
            text: "Game ID",
            field: "game_id",
            filterable: true,
            value: route.query.game_id,
            show: false
        },
        {
            text: "Event Time",
            field: "log_time",
            filterable: true,
            type: 'date',
            show: true,
            default_begin: Date.now() - 24 * 60 * 60 * 1000,
            default_end: Date.now()
        },
        {
            text: "Version",
            field: "version",
            filterable: true,
            show: true
        },
        {
            text: "Operating System",
            field: "operating_system",
            filterable: true,
            show: true
        }
    ])

    const onLogClick = (log, rows, index) => {
        if (log.module_id.includes('PlayerInSessionServer')) {
            window.open('/game-logs?user_id=' + log.exception_message.substring(22))
        }
        else if (log.module_id.includes('PlayerInSession')) {
            window.open('/game-logs?user_id=' + log.exception_message.substring(15))
        }
        logIndex.value = index;
        openLogPopup(log, rows, index)
    }


    return {
      columns,
      logPopupOpen,
      logIndex,
      openLogPopup,
      popupContent,
      logRows,
      onLogClick
    }
  }
}
</script>

<style scoped>
.table {
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 5%;
}
</style>
